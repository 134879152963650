import { OldhamTravelTypeSwitcherController } from "./oldham-travel-type-switcher.controller";

export class OldhamTravelTypeSwitcher {
  controller: any = OldhamTravelTypeSwitcherController;
  template: string = `
    <div class="switch-container">
      <div class="hotels-container"
        ng-class="{ 'active': $ctrl.globalStateService.isTravelType('hotels')}"
        ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('hotels')">
        <div class="icon">
        </div>
        <div>
          Hotels
        </div>
      </div>
      <div class="flights-container"
        ng-class="{ 'active': $ctrl.globalStateService.isTravelType('flights')}"
        ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('flights')">
        <div class="icon">
        </div>
        <div>
          Flights
        </div>
      </div>
      <div class="cars-container"
        ng-class="{ 'active': $ctrl.globalStateService.isTravelType('cars')}"
        ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('cars')">
        <div class="icon">
        </div>
        <div>
          Cars
        </div>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("oldhamTravelTypeSwitcher", new OldhamTravelTypeSwitcher());
