import { AppSettings } from "booking_app/values/app-settings";

export class CouponBannerController {

  static $inject = [
    "$rootScope",
    "AppSettings"
  ];

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
  ) {}
}
