import { PointsPartnerSelectionController } from "./points-partner-selection.controller";

export class PointsPartnerSelectionComponent {
  controller: any = PointsPartnerSelectionController;
  template: string = `
    <ui-select
      ng-change="$ctrl.changePointsPartner()"
      ng-model="$ctrl.selectedPointsPartner"
      id="points-selector"
      theme="select2"
      search-enabled="false">
      <ui-select-match placeholder="{{ $ctrl.selectedPointsPartner.partner }}">
        <div class="selected-value" ng-bind-html="$ctrl.selectedPartnerLabel()"></div>
      </ui-select-match>
      <ui-select-choices
        ui-disable-choice="pointsPartner.disabled"
        repeat="pointsPartner in $ctrl.pointsPartners">
          <div class="input-options"
            ng-class="{'select2-header': pointsPartner.isHeader }"
            ng-bind="$ctrl.getPointsPartnerSummary(pointsPartner)">
          </div>
      </ui-select-choices>
    </ui-select>
  `;
}

angular.module("BookingApp").component("pointsPartnerSelection", new PointsPartnerSelectionComponent());
