import { Bindings } from "booking_app/types";
import { CookieModalController } from "./cookie-modal.controller";

export class CookieModalComponent {
  bindings: any = {
    iconCdnPath: Bindings.ONE_WAY,
    textContent: Bindings.ONE_WAY,
  };
  controller: any = CookieModalController;
  template: string = `
    <div class="cookie-modal-container" ng-if="$ctrl.showCookieModal()">
      <img class="cookie-modal-icon" cdn-path="{{ $ctrl.iconCdnPath }}" ng-if="$ctrl.iconCdnPath">
      <div class="cookie-modal-text" ng-bind-html="$ctrl.textContent"></div>
      <span class="cookie-modal-close" ng-click="$ctrl.closeCookieModal()">✕</span>
    </div>
  `;
}

angular.module("BookingApp").component("cookieModal", new CookieModalComponent());
