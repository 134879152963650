import { MetaData } from "booking_app/types/meta-data";

export const metaData: MetaData = {
  "home": {
    title: "Travel with OCBC | Enjoy Discounted Rates, Redeem With Points",
    metaDescription: `Find the best prices for hotels,
      flights and car rental, exclusive to OCBC Cardholders. Travel made easy with OCBC. Plan your trip today.`,
  },
  "hotel-home": {
    title: "Travel with OCBC | Search Hotels",
    metaDescription: `Discover and find the best prices for over 900,000 properties worldwide.
      Save more on your next trip with OCBC. Book your hotel today.`,
  },
  "car-home": {
    title: "Travel with OCBC | Search Cars",
    metaDescription: `Search car rentals from over 200 rental locations worldwide.
      Enjoy discounted rates exclusive to OCBC Cardholders. Book your car rental today.`,
  },
  "flight-home": {
    title: "Travel with OCBC | Search Flights",
    metaDescription: `Find and book flights from over 800 domestic and international airlines.
      Redeem your OCBC reward points instantly on all flight bookings. Book your flights today.`,
  },
  "manage-booking": {
    title: "Travel with OCBC | Manage Bookings",
  },
  "contact-us": {
    title: "Travel with OCBC | Contact Us",
    metaDescription: `Contact us for any enquiries, feedback or support-related matters.
      Our friendly customer support team monitors messages 24/7 and will respond as soon as they can.`,
  },
  "faq": {
    title: "Travel with OCBC | FAQs",
    metaDescription: `Find answers to frequently asked questions.
      For all other enquiries, please contact customer support.`,
  },
  "terms-condition": {
    title: "Travel with OCBC | Terms of Use",
    metaDescription: `View our Terms and Conditions for the use of Travel with OCBC booking portal.`,
  },
  "privacy-policy": {
    title: "Travel with OCBC | Privacy Policy",
    metaDescription: `View our Privacy Policy for the use of Travel with OCBC booking portal.`,
  },
  // Cars
  "car-result": {
    title: "Travel with OCBC | Search Cars",
    metaDescription: `Search car rentals from over 200 rental locations worldwide.
      Enjoy discounted rates exclusive to OCBC Cardholders. Book your car rental today.`,
  },
  "car-detail": {
    title: "Travel with OCBC | Search Cars",
    metaDescription: `Search car rentals from over 200 rental locations worldwide.
      Enjoy discounted rates exclusive to OCBC Cardholders. Book your car rental today.`,
  },
  "car-checkout": {
    title: "Travel with OCBC | Complete Your Booking",
  },
  "car-success": {
    title: "Travel with OCBC | Booking Confirmation",
  },
  // Flights
  "flight-result": {
    title: "Travel with OCBC | Search Flights",
    metaDescription: `Find and book flights from over 800 domestic and international airlines.
      Redeem your OCBC reward points instantly on all flight bookings. Book your flights today.`,
  },
  "flight-detail": {
    title: "Travel with OCBC | Review Your Trip",
    metaDescription: `Find and book flights from over 800 domestic and international airlines.
      Redeem your OCBC reward points instantly on all flight bookings. Book your flights today.`,
  },
  "flight-checkout": {
    title: "Travel with OCBC | Complete Your Booking",
  },
  "flight-success": {
    title: "Travel with OCBC | Booking Confirmation",
  },
  // Hotels
  "search-result": {
    title: "Travel with OCBC | Search Hotels",
    metaDescription: `Discover and find the best prices for over 900,000 properties worldwide.
      Save more on your next trip with OCBC. Book your hotel today.`,
  },
  "hotel-detail": {
    title: "Travel with OCBC | Search Hotels",
    metaDescription: `Discover and find the best prices for over 900,000 properties worldwide.
      Save more on your next trip with OCBC. Book your hotel today.`,
  },
  "checkout": {
    title: "Travel with OCBC | Complete Your Booking",
  },
  "confirmation": {
    title: "Travel with OCBC | Booking Confirmation",
  },
};
