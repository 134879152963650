import { PayAnyoneController } from "./pay-anyone.controller";
import { Bindings } from "booking_app/types/bindings";

export class PayAnyoneComponent {
  controller: any = PayAnyoneController;
  bindings: any = {
    price: Bindings.ONE_WAY,
  };
  template: string = `
  <div class="pay-anyone-qr-code-container">
    <div class="title-section">
      <span class="title">{{ $ctrl.payAnyoneService.payment_by() | translate }}</span>
      <a ng-click="$ctrl.resetBackToCheckoutPage()">
        <img ng-src="{{ $ctrl.payAnyoneService.getImagePath() }}/icons/arrow-back.svg">
        {{ "wl.pay_anyone_return_to_checkout" | translate }}
      </a>
    </div>
    <div class="balance-section">
      <span class="balance-label">{{ "wl.pay_anyone_balance_due "| translate }}
        <span class="balance">SGD {{ $ctrl.price }} </span>
      </span>
      <span class="expiration-label">{{ "wl.pay_anyone_expiring_in" | translate }}
        <span class="timer">{{ $ctrl.getCounter() }} </span>
      </span>
    </div>
    <div class="qr-code-section">
      <div class="qr-code">
        <qrcode data="{{ $ctrl.payAnyoneService.qrCode }}"></qrcode>
      </div>
      <div class="how-to">
        <div class="title">
        {{ "wl.pay_anyone_how_to_payment" | translate }}
        </div>
        <span class="statement">
        {{ $ctrl.payAnyoneService.scan_qr() | translate }}
        </span>
        <span class="description">
        {{ "wl.pay_anyone_confirmation_note" | translate }}
        <a href=\"mailto:{{ $ctrl.$rootScope.globalState.serviceEmail }}\">{{ $ctrl.$rootScope.globalState.serviceEmail }}.</a>
        </span>
      </div>
    </div>
  </div>
  `;
}

angular.module("BookingApp").component("payAnyone", new PayAnyoneComponent());
