import { CookieService } from "booking_app/services/cookie.service";

export class CookieModalController {
  static $inject = [
    "$rootScope",
    "CookieService",
  ];

  constructor(
    private $rootScope: any,
    private cookieService: CookieService,
  ) {}

  showCookieModal(): boolean {
    return !this.cookieService.fetchCookie("cookieModal") && !this.$rootScope.isUserLoggedIn();
  }

  closeCookieModal(): void {
    this.cookieService.setCookie("cookieModal", true);
  }
}
