export class ProfileDropdownController {

  private openDropdown: boolean;

  // bindings
  private logOut: () => {};

  private toggleDropdown(): void {
    this.openDropdown = !this.openDropdown;
  }

}
