import { AdobeAnalyticsService } from "booking_app/services/adobe-analytics.service";
import { PageDiscoveryService } from "booking_app/services/page-discovery.service";
import { AppSettings } from "booking_app/values/app-settings";
import { UserAgentChecker } from "booking_app/utils/user-agent-checker";
import { AdobeAnalyticsPageDetails, CurrentPage, TravelType } from "booking_app/types";
import { Booking, CancellationPolicy } from "booking_app/models";

export interface OCBCData {
  page: {
    pageName: string;
    language: "en",
    lob: TravelType,
    currency: "SGD",
    pointsCurrency: string;
  };
  user: {
    authState: "logged in" | "not logged in";
    CIF: string;
  };
  event: {
    pageView: string;
  };
  payment?: PaymentData;
}

export interface PaymentData {
  bookingId: string;
  paymentType: paymentType;
  pointsAmount: number;
  cashAmount: number;
  pointsCurrencyName: string;
  refundable: string;
  status: string;
}

type paymentType = "payanyone" | "ocbc credit or debit card";

export class OldhamAdobeAnalyticsService extends AdobeAnalyticsService {

  constructor(
    $rootScope: any,
    $window: any,
    pageDiscoveryService: PageDiscoveryService,
    appSettings: AppSettings,
    userAgentChecker: UserAgentChecker,
  ) {
    super(
      $rootScope,
      $window,
      pageDiscoveryService,
      appSettings,
      userAgentChecker,
    );
    /**
     * trackAdobeAnalytics event is currently only being listened in ocbc
     * as part of the ongoing migration of supporting pageData for adobe analytics.
     * pageData is type any as of now. Because pageData could be anything returned
     * from the backend in results, details etc. Once fully migrated, we can perhaps
     * come up with a strong type checking for pageData.
    */

    $rootScope.$on("trackAdobeAnalytics", (event, pageData: any) => {
      this.trackAnalytics(this.pageDiscoveryService.currentPage(), pageData);
    });
  }

  public initiateAnalyticsOnRouteChange(): void {
    this.pageDiscoveryService.listenRouteChange(this.useV1AnalyticsTracking.bind(this));
  }

  protected trackAnalytics(currentPage: CurrentPage, pageData?: any): void {
    const adobeAnalyticsPageDetailsObject: AdobeAnalyticsPageDetails = this.adobeAnalyticsData[currentPage];
    // below will remove undefined values from the constructedObject
    const ocbcData: OCBCData = JSON.parse(
      JSON.stringify(this.constructOcbcDataObject(adobeAnalyticsPageDetailsObject, pageData),
    ));
    this.$window.ocbcData = { ...ocbcData };
    this.$window._satellite.track("pageView");
  }

  private constructOcbcDataObject(
    adobeAnalyticsPageDetailsObject: AdobeAnalyticsPageDetails,
    pageData?: any): OCBCData {
    return {
      page: {
        pageName: adobeAnalyticsPageDetailsObject.pageName,
        language: "en",
        lob: adobeAnalyticsPageDetailsObject.travelType,
        currency: "SGD",
        pointsCurrency: this.$rootScope.pointsPartner.partner,
      },
      user: {
        authState: this.$rootScope.isUserLoggedIn() ? "logged in" : "not logged in",
        CIF: this.$rootScope.isUserLoggedIn() ?
          this.$rootScope.userDetails.user.redemption_member_no : "",
      },
      payment: pageData ? this.constructPaymentObject(pageData) : undefined,
      event: {
        pageView: "true",
      },
    };
  }

  private constructPaymentObject(pageData: Booking): PaymentData {
    return {
      bookingId: pageData.kaligo_booking_ref,
      paymentType: this.getPaymentType(pageData.payment_type),
      pointsAmount: pageData.paid_in_points,
      cashAmount: pageData.converted_amount,
      pointsCurrencyName: pageData.points_partner.name,
      refundable: this.getRefundableStatus(pageData.cancellation_policy),
      status: pageData.status,
    };
  }

  private useV1AnalyticsTracking(): void {
    /**
     * Temporary method to support analytics event triggers from
     * trackAdobeAnalytics broadcast. This method ensures that we do not call
     * adobe tracking twice for the same page (trackAdobeAnalytics event
     * and the routeChange listener event). Once the migration is done, we can
     * get rid of this method and do below instead
     * this.pageDiscoveryService.listenRouteChange(this.trackAnalytics.bind(this));
    */
    const currentPage: CurrentPage = this.pageDiscoveryService.currentPage();
    // Extend below if condition for each page when adding pageData support
    const excludedPagesForV1Tracking = [
      CurrentPage.CONFIRMATION, CurrentPage.FLIGHT_SUCCESS, CurrentPage.CAR_SUCCESS,
    ];
    if (!excludedPagesForV1Tracking.includes(currentPage)) {
      this.trackAnalytics(currentPage);
    }
  }

  private getPaymentType(type: string): paymentType {
    return type === "pay_anyone" ? "payanyone" : "ocbc credit or debit card";
  }

  private getRefundableStatus(policy: CancellationPolicy): string {
    return policy ? policy.refundable : "no";
  }

}

angular.module("BookingApp").service("OldhamAdobeAnalyticsService", OldhamAdobeAnalyticsService);
