import { CouponBannerController } from "./coupon-banner.controller";

export class CouponBannerComponent {
  controller: any = CouponBannerController;
  template: string = `
    <div class="coupon-banner-container">
      <i class="coupon-icon"></i>
      <span ng-if="!$ctrl.$rootScope.isUserLoggedIn()">
        Log in with your OCBC internet banking now and enjoy exclusive rates
      </span>
      <span ng-if="$ctrl.$rootScope.isUserLoggedIn()">
        {{ $ctrl.appSettings.mastheadBannerSettings.couponCodeText }}
      </span>
    </div>
  `;
}

angular.module("BookingApp").component("couponBanner", new CouponBannerComponent());
