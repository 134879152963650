import { ProfileDropdownController } from "./profile-dropdown.controller";
import { Bindings } from "booking_app/types";

export class ProfileDropdownComponent {
  bindings: any = {
    logOut: Bindings.EXPRESSION,
  };
  controller: any = ProfileDropdownController;
  template: string = `
  <div class="profile-dropdown" click-outside="$ctrl.openDropdown=false">
    <div class="member-img" ng-click="$ctrl.toggleDropdown()">
    </div>
    <div class="dropdown-container" ng-if="$ctrl.openDropdown">
      <span class="manage-booking-option">
        <div class="fa fa-suitcase"></div>
        <a
          href= "/account/booking"
          ng-click = "$ctrl.toggleDropdown()"
          translate="{{ 'Manage Bookings' }}">
        </a>
      </span>
      <span class="logout-option">
        <div class="fa fa-logout"></div>
        <a
          ng-click="$ctrl.logOut()"
          translate="{{ 'Log out' }}">
        </a>
      </span>
    </div>
  </div>
  `;
}

angular.module("BookingApp").component("profileDropdown", new ProfileDropdownComponent());
