import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { PointsPartner } from "booking_app/types/points-partner";
import { PointsPartnerService } from "../../services/points-partner.service";
import { PointsPartnerMapperService } from "../../services/points-partner-mapper.service";

export class PointsPartnerSelectionController {

  static $inject = [
    "$rootScope", "$location", "$translate", "PointsPartnerService", "PointsPartnerMapperService",
  ];

  private selectedPointsPartner: any;
  private pointsPartners: any;

  constructor(
    private $rootScope: any,
    private $location: any,
    private $translate: any,
    private pointsPartnerService: PointsPartnerService,
    private pointsPartnerMapper: PointsPartnerMapperService,
  ) {
  }

  $onInit(): void {
    this.setupCurrentPointsPartnerBalance();
  }

  changePointsPartner(): void {
    this.pointsPartnerService.setDefaultPointsPartner(this.selectedPointsPartner);
  }

  selectedPartnerLabel(): string {
    const selectedPointsPartner = this.pointsPartnerMapper.pointsPartnerList[this.$rootScope.pointsPartner.partner];
    if (this.$rootScope.globalState.browserWidth <= ViewportSizes.SM_MAX) {
      return selectedPointsPartner;
    } else {
      return this.$translate.instant(`You're browsing in <strong>${selectedPointsPartner}</strong>`);
    }
  }

  getPointsPartnerSummary(pointsPartner: PointsPartner): string {
    const pointsPartnerName = this.pointsPartnerMapper.pointsPartnerList[pointsPartner.partner];
    if (this.$rootScope.isUserLoggedIn() && pointsPartner.points) {
      return `${pointsPartnerName}: ${pointsPartner.points.toLocaleString()}`;
    } else {
      return pointsPartnerName ? pointsPartnerName : pointsPartner.partner;
    }
  }

  private setupPointsPartners(): void {
    const pointsPartners =  angular.copy(this.$rootScope.pointsPartners.all);
    this.selectedPointsPartner = this.pointsPartnerService
      .findPointsPartnerById(this.$rootScope.landingPage.points_partner_id);
    this.pointsPartners = pointsPartners;

    if (!this.$rootScope.isUserLoggedIn()) {
      this.pointsPartnerService.setDefaultPointsPartner(this.selectedPointsPartner);
      this.pointsPartners.map( partner => {
        if ( partner.id !== this.selectedPointsPartner.id ) {
          partner.disabled = true;
        }
      });
      this.pointsPartners.unshift(
        { value: "",
          partner: this.$translate.instant("Login to browse in another currency"),
          disabled: true,
          isHeader: true,
        },
      );
    } else {
      this.pointsPartners = this.setupPointBalanceForPointsPartner(pointsPartners);
      this.setupCurrentPointPartner(pointsPartners);
    }
  }

  private setupCurrentPointPartner(pointsPartners: PointsPartner[]): void {

    const hasEveryPPHasZeroValue = pointsPartners.every(pp => pp.points === 0);
    const highestPPValueWithId = hasEveryPPHasZeroValue ? this.$rootScope.pointsPartner :
      this.pointsPartnerService.getPointsPartnerWithHighestPointsValue(pointsPartners);
    const searchParams = this.$location.search();
    const partnerIdFromSearchParam = searchParams.partnerId;
    const isLoginRedirect = searchParams.loginRedirect;

    // if => loginRedirect is true when user redirect from login or OCBC app deeplink redirect
    // elseif => user refresh non landingPage, so we keep points partner in url
    // else => user in landing page, there is no partnerId in url. So we select highest PP value
    if (isLoginRedirect) {
      this.selectedPointsPartner = this.pointsPartnerService.findPointsPartnerById(highestPPValueWithId.id);
      this.pointsPartnerService.overrideUrlPointsPartner(this.selectedPointsPartner);
    } else if (partnerIdFromSearchParam) {
      this.selectedPointsPartner = this.pointsPartnerService.findPointsPartnerById(parseInt(partnerIdFromSearchParam));
    } else {
      this.selectedPointsPartner = this.pointsPartnerService.findPointsPartnerById(highestPPValueWithId.id);
    }

    this.$rootScope.pointsPartner = this.selectedPointsPartner;
    this.pointsPartners = this.moveSelectedPointsPartnerToTop(this.pointsPartners);
  }

  private setupPointBalanceForPointsPartner(pointsPartners: PointsPartner[]): PointsPartner[] {
    const pointBalances = this.$rootScope.userDetails.user.points_balances;
    if (!pointBalances) {
      return pointsPartners.map(pointsPartner => ({...pointsPartner, disabled: true}));
    }

    const pointsPartnerWithPoints: PointsPartner[] = [];
    pointsPartners.forEach(pointsPartner => {
      const pointsBalance = pointBalances.find(pointBalance =>
        this.checkIfPointsPartnerExists(pointsPartner.partner, pointBalance.PoolName),
      );
      // disable the point partner not in user list or quantity is 0
      if (!pointsBalance || pointsBalance.Quantity === 0) {
        pointsPartner.disabled = true;
        pointsPartner.points = 0;
      } else {
        pointsPartner.points = pointsBalance.Quantity;
      }
      pointsPartnerWithPoints.push(pointsPartner);
    });
    return pointsPartnerWithPoints;
  }

  private moveSelectedPointsPartnerToTop(pointsPartnerArr: PointsPartner[]): PointsPartner[] {
    /**
     * Selected points partner needs to be moved to top of the array so that
     * it will be showed highlighted on top of the dropdown.
     */
    const selectedPPIndex = pointsPartnerArr
      .findIndex(pointsPartner => pointsPartner.partner === this.selectedPointsPartner.partner);
    pointsPartnerArr.unshift(pointsPartnerArr.splice(selectedPPIndex, 1)[0]);
    return pointsPartnerArr;
  }

  private setCurrentPointsPartnerBalance(): void {
    /**
     * We keep PointsPartnerMapper to map partner value from OCBC to our own value.
     * In order for us to set the points balance, We need to match the correct
     * partner from TE side to this map and based on the selected partner value
     */

    if (!this.$rootScope.isUserLoggedIn()) {
      return;
    }

    const pointBalances = this.$rootScope.userDetails.user.points_balances;
    if (!pointBalances) {
      return;
    }

    const pointsPartners =  angular.copy(this.$rootScope.pointsPartners.all);
    const currentPointsPartner = this.$rootScope.pointsPartner.partner;
    pointsPartners.forEach(pointsPartner => {
      const pointsBalance = pointBalances
        .find(pointBalance =>
          this.checkIfPointsPartnerExists(pointsPartner.partner, pointBalance.PoolName) &&
          pointsPartner.partner === currentPointsPartner,
        );
      if (pointsBalance) {
        this.$rootScope.userDetails.selectedPointsBalance = pointsBalance.Quantity;
        return;
      }
    });
  }

  private setupCurrentPointsPartnerBalance(): void {
    this.$rootScope.$watch("userDetails", () => {
      this.setupPointsPartners();
      this.setCurrentPointsPartnerBalance();
    });
  }

  private checkIfPointsPartnerExists(partner: string, poolName: string): boolean {
    /**
     * Helper method to check if the incoming points partner has a matching entry
     * in the PointsPartnerMapper.
     */
    return this.pointsPartnerMapper.pointsPartnerList[partner] === this.pointsPartnerMapper.pointsPartnerList[poolName];
  }

}
