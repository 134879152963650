import { PaymentMethodService } from "booking_app/services/payment-method.service";
import { PayAnyoneService } from "booking_app/services/payments/pay-anyone.service";

export class PayAnyoneController {
  static $inject = [
    "PayAnyoneService",
    "PaymentMethodService",
    "$rootScope",
    "$timeout",
    "$filter",
    "$window",
  ];

  // bindings
  private price: string;
  private counter: number = 300;

  /* countDownTimer is created as function assignment
     To avoid lexical scope this issue.
  */
  private countDownTimer = function() {
  /**
   * The reload trigger takes about 1 second to execute
   * Therefore execute reload when timer reaches
   * T-1 seconds
  */
    this.counter--;
    if (this.counter === 1) {
      this.$window.location.reload();
    }
    this.$timeout(this.countDownTimer, 1000);
  }.bind(this);

  constructor(
    private payAnyoneService: PayAnyoneService,
    private paymentMethodService: PaymentMethodService,
    private $rootScope: any,
    private $timeout: any,
    private $filter: any,
    private $window: any,
  ) {
  }

  public $onInit(): void {
    this.countDownTimer();
  }

  public resetBackToCheckoutPage(): void {
    this.$window.location.reload();
  }

  public getCounter(): string {
    return `00:${this.$filter("date")
      (new Date(1970, 0, 1).setSeconds(this.counter), "mm:ss")}`;
  }

}
