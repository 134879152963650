export class PointsPartnerMapperService {
  public pointsPartnerList = {};
  constructor() {
    this.setupPointsPartnerList();
  }

  setupPointsPartnerList(): void {
    this.pointsPartnerList = PointsPartnerList;
  }
}
angular.module("BookingApp").service("PointsPartnerMapperService", PointsPartnerMapperService);

export const PointsPartnerList: { [key: string]: string } = {
  "OCBC$": "OCBC$",
  "Voyage Miles": "VOYAGE Miles",
  "VOYAGE Miles": "VOYAGE Miles",
  "Travel$": "90°N Miles",
  "90N Miles": "90°N Miles",
};
