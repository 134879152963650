import { GlobalStateService } from "booking_app/services/global-state.service";

export class OldhamTravelTypeSwitcherController {
  static $inject = [
    "GlobalStateService",
  ];

  constructor(
      private globalStateService: GlobalStateService,
  ) {}

}
