import { PaymentSettings } from "booking_app/types/payment-settings";

export class PaymentStylingSettings {
  public paymentSettings: PaymentSettings;
  constructor(
  ) {
    this.setupPaymentStyling();
  }

  setupPaymentStyling(): void {
    this.paymentSettings = {
      stripe: {
        base: {
          fontSize: "16px",
          fontSmoothing: "antialiased",
          fontFamily: "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
        },
        invalid: {
          color: "#282828",
        },
      },
    };
  }
}

angular.module("BookingApp").service("PaymentStylingSettings", PaymentStylingSettings);
