interface WidgetOption {
  title: string;
  body: string;
}

export class HeaderNotificationController {
  static $inject = [
    "AppConfig",
    "$rootScope",
    "$scope",
  ];

  public showPopup: boolean;
  public onScrollEvent: () => void;

  constructor(
    private appConfig: any,
    private $rootScope: any,
    private $scope: any,
  ) {}

  $onInit(): void {
    this.showPopup = false;
    this.onScrollEvent = (): void => {
      this.togglePopup(false);
      this.$scope.$apply();
    };
  }

  togglePopup(state: boolean): void {
    if (state !== this.showPopup) {
      this.showPopup = state;
    }

    if (this.showPopup) {
      window.addEventListener("scroll", this.onScrollEvent);
    } else {
      window.removeEventListener("scroll", this.onScrollEvent);
    }
  }

  iconContent(): string {
    const imagePath: string = this.appConfig.widget.icon;
    return `${this.$rootScope.const.cdnUrl}${imagePath}`;
  }

  titleContent(): string {
    return this.widgetContent().title;
  }

  popupContent(): string {
    return this.widgetContent().body;
  }

  widgetContent(): WidgetOption {
    const localeCode: string = this.$rootScope.selectedLocale.code;
    return this.appConfig.widget[localeCode];
  }
}
