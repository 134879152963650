import "./page-discovery.service";

import { CurrentPage, MetaData, PageMetaData } from "booking_app/types";
import { PageDiscoveryService } from "./page-discovery.service";

export class DocumentService {

  static $inject = ["PageDiscoveryService"];

  constructor(
    private pageDiscoveryService: PageDiscoveryService,
  ) {}

  public initDocumentTitleAndMeta(metaData: MetaData): void {
    this.pageDiscoveryService.listenRouteChange(this.setDocumentMetaDataCallBack.bind(this, metaData));
  }

  private setDocumentMetaDataCallBack(metaData: MetaData, currentPage: CurrentPage ): void {
    let currentPageMetaData: PageMetaData = metaData[currentPage];

    if (!currentPageMetaData) {
      currentPageMetaData = metaData.home;
    }

    document.title = currentPageMetaData.title;
    (document.getElementsByName("description")[0] as HTMLMetaElement).content =
      currentPageMetaData.metaDescription ?? "";
  }

}

angular.module("BookingApp").service("DocumentService", DocumentService);
