import { AdobeAnalyticsData, TravelType } from "booking_app/types";

export const adobeAnalyticsData: AdobeAnalyticsData  = {
  "home": {
    pageName: "sg:ocbc:travel:hotel:main",
    travelType: TravelType.HOTELS,
  },
  "hotel-home": {
    pageName: "sg:ocbc:travel:hotel:main",
    travelType: TravelType.HOTELS,
  },
  // Hotels
  "search-result": {
    pageName: "sg:ocbc:travel:hotel:search results",
    travelType: TravelType.HOTELS,
  },
  "hotel-detail": {
    pageName: "sg:ocbc:travel:hotel:details",
    travelType: TravelType.HOTELS,
  },
  "checkout": {
    pageName: "sg:ocbc:travel:hotel:checkout",
    travelType: TravelType.HOTELS,
  },
  "confirmation": {
    pageName: "sg:ocbc:travel:hotel:booking confirmation",
    travelType: TravelType.HOTELS,
  },
  // cars
  "car-home": {
    pageName: "sg:ocbc:travel:cars:main",
    travelType: TravelType.CARS,
  },
  "car-result": {
    pageName: "sg:ocbc:travel:cars:search results",
    travelType: TravelType.CARS,
  },
  "car-detail": {
    pageName: "sg:ocbc:travel:cars:add-ons",
    travelType: TravelType.CARS,
  },
  "car-checkout": {
    pageName: "sg:ocbc:travel:cars:checkout",
    travelType: TravelType.CARS,
  },
  "car-success": {
    pageName: "sg:ocbc:travel:cars:booking confirmation",
    travelType: TravelType.CARS,
  },
  // Flights
  "flight-home": {
    pageName: "sg:ocbc:travel:flight:main",
    travelType: TravelType.FLIGHTS,
  },
  "flight-result": {
    pageName: "sg:ocbc:travel:flight:search results",
    travelType: TravelType.FLIGHTS,
  },
  "flight-detail": {
    pageName: "sg:ocbc:travel:flight:review",
    travelType: TravelType.FLIGHTS,
  },
  "flight-checkout": {
    pageName: "sg:ocbc:travel:flight:checkout",
    travelType: TravelType.FLIGHTS,
  },
  "flight-success": {
    pageName: "sg:ocbc:travel:flight:booking confirmation",
    travelType: TravelType.FLIGHTS,
  },
  // common
  "manage-booking": {
    pageName: "sg:ocbc:travel:manage bookings",
  },
  "contact-us": {
    pageName: "sg:ocbc:travel:contact us form",
  },
  "faq": {
    pageName: "sg:ocbc:travel:faq",
  },
  "terms-condition": {
    pageName: "sg:ocbc:travel:terms of use",
  },
  "privacy-policy": {
    pageName: "sg:ocbc:travel:privacy policy",
  },
};
