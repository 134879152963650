require('../cars-setup');
require('../flights-setup');

global.loadWlDeps = function() {
  require('angular-counter/js/angular-counter');
}

global.wlAngularDeps = [
  'ngAnimate',
  'monospaced.qrcode',
];

global.loadAppDeps = function() {
  loadCarsAppDeps();
  loadFlightsAppDeps();
  require('angular-animate');
  require('angular-qrcode');
  require('../../assets/javascripts/whitelabel/oldham/locales');
  require('../../assets/javascripts/whitelabel/oldham/controllers/oldham-controller');
  require('../../assets/javascripts/whitelabel/oldham/services');
  require('../../assets/javascripts/whitelabel/oldham/components');
}

require('../application');
