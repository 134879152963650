import { Bindings } from "booking_app/types";
import { HeaderNotificationController } from "./header-notification.controller";

export class HeaderNotificationComponent {
  controller: any = HeaderNotificationController;
  template: string = `
    <div ng-if="$ctrl.appConfig.widget">
      <div class="notice-container" ng-click="$ctrl.togglePopup(!$ctrl.showPopup)">
        <img ng-src="{{$ctrl.iconContent()}}"></img>
        <span ng-bind-html="$ctrl.titleContent()"></span>
      </div>
      <div class="notice-message" ng-if="$ctrl.showPopup" click-outside="$ctrl.showPopup && $ctrl.togglePopup(false)">
        <div class="close-button" ng-click="$ctrl.togglePopup(false)">
          <span class="close-symbol"> ✕ </span>
        </div>
        <div class="message"
             ng-bind-html="$ctrl.popupContent()">
        </div>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("headerNotification", new HeaderNotificationComponent());
