import { PointsPartner } from "booking_app/types/points-partner";

export const PointsPartnerValueMap: { [key: string]: number }  = {
  /**
   * value = (actual_pp_value * 50) + 10
   */
  "OCBC$": 27.5,
  "VOYAGE Miles": 55,
  "Travel$": 52.5,
};

export interface PointsPartnerWithPointsValue {
  id: number;
  pointsValue: number;
}

export class PointsPartnerService {
  static $inject = [
    "$rootScope",
    "$window",
    "$location",
  ];

  private pointsPartnerValueMap = PointsPartnerValueMap;

  constructor(
    private $rootScope: any,
    private $window: any,
    private $location: any,
  ) {
  }

  public getPointsPartnerWithHighestPointsValue(pointsPartners: PointsPartner[]): PointsPartnerWithPointsValue {
    const pointsPartnerArrAfterValueCalculation: PointsPartnerWithPointsValue[] = [];
    pointsPartners.map(pp => {
      pointsPartnerArrAfterValueCalculation.push(
        {
          id: pp.id,
          pointsValue: pp.points * PointsPartnerValueMap[pp.partner],
        },
      );
    });
    return this.pointsPartnerByMaxValue(pointsPartnerArrAfterValueCalculation);
  }

  public setDefaultPointsPartner(selectedPointsPartner: PointsPartner): void {
    if (this.$rootScope.pointsPartner.id !== selectedPointsPartner.id) {
      this.$rootScope.pointsPartner = selectedPointsPartner;
      const searchParams = angular.merge(this.$location.search(), { partnerId: selectedPointsPartner.id});
      const searchUrl = this.$location.search(searchParams).absUrl();
      this.$window.location.href = searchUrl;
    }
  }

  public overrideUrlPointsPartner(selectedPointsPartner: PointsPartner): void {
    const overrideParams = {
      partnerId: selectedPointsPartner.id,
      loginRedirect: null,
    };
    const searchParams = angular.merge(this.$location.search(), overrideParams);
    this.$location.path(this.$location.path(), false).search(searchParams);
  }

  public findPointsPartnerById(pointsPartnerId: number): PointsPartner {
    return this.$rootScope.pointsPartners.findById(pointsPartnerId);
  }

  private pointsPartnerByMaxValue(pointsPartnerArrAfterValueCalculation:
    PointsPartnerWithPointsValue[]): PointsPartnerWithPointsValue {
      return pointsPartnerArrAfterValueCalculation.reduce((prev, current) => {
        return (prev.pointsValue > current.pointsValue) ? prev : current;
    });
  }

}

angular.module("BookingApp").service("PointsPartnerService", PointsPartnerService);
